html {
    font-family: Arial,Helvetica,sans-serif, "Trebuchet MS", Tahoma, Georgia, "Times New Roman", Times;
    font-size: 100%;
}
html, body {
    height:auto !important; 
    height:100%; 
    min-height:100%;
}
body {
    margin: 0;
    padding: 0;
    overflow:auto;
    color: #979797;
/*    background-image: url("../img/main_bg.png"); */
    overflow-y: hidden;	
    line-height: 1;
}
.text_center {
    text-align:center;
}
#tabel_clienti .p-datatable-scrollable-body {
    min-height:213px !important;
    cursor:pointer !important;
}
.icon_button {
    margin-left:-15px;
    margin-right:6px;
}
.buton_cautare {
    margin-top:8px !important;
    width:153px !important;
    margin-right:9px !important;
}
.MuiDialogTitle-root {
    padding-bottom: 3px !important;
}
.icon_button_header {
    margin-left:-15px;
    margin-right:6px;
    width:171px;
}
.MuiTabs-root {
    min-height:auto !important;
}
.MuiDialog-paperScrollPaper {
    max-height:calc(100% - 45px) !important;
    margin-top:54px;
}
.button_unselected {
    background-color: blue !important;
}
.button_selected {
    background-color: red !important;
}
#lista_items_1 .p-datatable-scrollable-body {
    min-height:480px !important;
    cursor:pointer !important;
}
.MuiOutlinedInput-inputMarginDense {
    padding-top: 9px;
    padding-bottom: 9px;
}
#fisa_auto_pdf .p-toggleable-content {
    height: 90%;
}
#cos_servicii_facturare .MuiAutocomplete-input {
    padding-top:0px !important;
    padding-bottom:0px !important;
}
#cos_servicii_facturare .MuiInputLabel-outlined {
    line-height:0.3 !important;
}
#cos_servicii_servicii .MuiAutocomplete-input {
    padding-top:0px !important;
    padding-bottom:0px !important;
}
#cos_servicii_servicii .MuiInputLabel-outlined {
    line-height:0.3 !important;
}
.MuiContainer-root {
    height:90% !important;
}
#lista_items_2 .p-datatable-scrollable-body {
    min-height:384px !important;
    cursor:pointer !important;
}
#lista_items_3 .p-datatable-scrollable-body {
    min-height:342px !important;
    cursor:pointer !important;
}
